import React, { useEffect, useState } from "react";
// import LandingPageMob from '../../SaasOnboardingMobile/LandingPage/LandingPageMob'
import PricingPageMain2 from "../LandingPricing2/PricingPageMain2";
import LoginHeader from "../LoginHeader";
import Footer from "../PricingPage/Footer";
import LandingSection1 from "./LandingSection1";
import LandingSection2 from "./LandingSection2";
import LandingSection3 from "./LandingSection3";
import LandingSection4 from "./LandingSection4";
import LandingSection5 from "./LandingSection5";
import LandingSection6 from "./LandingSection6";
import LandingSection7 from "./LandingSection7";
import LandingSection8 from "./LandingSection8";
import MainMobComp from "../../SaasOnboardingMobile/LandingPage/MainMobComp";
import { Helmet } from "react-helmet";
import { webpageSchema } from "../../../../Schemas";
import {BsFillArrowUpCircleFill} from "react-icons/bs";
import CaseStudy from "./CaseStudy";
// import LandingSection9 from './LandingSection9'

const LandingPageMain = () => {
  const [visible, setVisible] = useState(false);
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    
    });
  }; 
  window.addEventListener('scroll', toggleVisible);

  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {webpageSchema(
            window.location.href,
            "Looking to Renovate your home?",
            "Interior Designers and Contractors Online Marketplace | iDesign Market",
            "iDesign Market is an online marketplace for interior design professionals in India. Find a right and reliable interior designer or contractor in your city."
          )}
        </script>
        <meta
          name="title"
          content="SaaS Platform for Interior, Architecture and Construction Co. | iDesign Market"
        />
        <meta
          name="description"
          content="iDesign Market is an online marketplace for interior design professionals in India. Find a right and reliable interior designer or contractor in your city."
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <title>
        SaaS Platform for Interior, Architecture and Construction Co. | iDesign Market
        </title>
      </Helmet>
      <div style={{ height: "fit-content" }}>
        {isDesktop ? (
          <div className="d-flex flex-column" style={{ overflowX: "hidden" }}>
            <LoginHeader
            />

            <LandingSection1 />
            <LandingSection2 />
            <LandingSection3 />
            <LandingSection4 />
            {/* <PricingPageMain2 /> */}
            <CaseStudy/>
            <LandingSection5 />
            <LandingSection6 />
            <LandingSection7 />
            <LandingSection8 />
            <div style={{position:"fixed",top:"90%",right:"5%",cursor:'pointer'}}>
            <BsFillArrowUpCircleFill onClick={scrollToTop} size={30} style={{display: visible ? 'inline' : 'none'}}/>
            </div>
 
            <Footer />

            {/* <LandingSection9/> */}
          </div>
        ) : (
          <MainMobComp />
        )}
      </div>
    </>
  );
};

export default LandingPageMain;
