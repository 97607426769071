import axios from "axios";
import React, { useContext, useEffect } from "react";
import { getToken } from "../../../Lms/getToken";
import dashContext from "../picontext/DashBoard/dashContext";
import { getReq } from "../piservices/apis";
import { getLoginId } from "../piservices/authService";
import imgx from "./Images/nochat.png";
import postBoxImg from "./Images/postBoxImg.svg";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { constants } from "../../../constants";

const NoChatYet = (props) => {
  const context = useContext(dashContext);

  const authTok = localStorage.getItem("token") ? getToken() : "";

  const { setAllAvChatRes, setShowChat, showChat, setMsg, searchkey, setSearchKey } = context;
  const init = async () => {
    if (showChat === true) {
      if (getToken() && getLoginId()) {
        // const call = await getReq(
        //     `https://pro-api.idesign.market/user/leads?pageNo=0`, {});
        //     console.log(call)
        const call = await axios({
          method: "get",
          url: `${constants.B2B_BASE}/user/leads?pageNo=0`,
          headers: {
            authorization: authTok,
          },
        });
        console.log(call?.data?.data?.data);
        if (call && !call.error) {
          setAllAvChatRes(call?.data?.data?.data);
        } else {
          console.log(call.error);
        }
      } else {
        document.getElementById("loginBtn").click();
      }
    } else {
      // console.log("object")
    }
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChat]);
  return (
    <div className="mv-vw-100 ">
      <div className="d-flex justify-content-between px-3 position-relative" style={{width: "95%"}}>
        <div className="input-group rounded position-absolute chatsearch bg-white start-0 mx-2" style={{ top: "-55px", opacity: "0", transition: "0.5s", width: "95%" }}>
          <input
            type="search"
            className="form-control rounded-pill mx-3 ms-0"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-addon"
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
            value={searchkey}
          />
          {/* <span className="input-group-text border-0" id="search-addon">
                        <i className="fas fa-search" />
                    </span> */}
          <span
            className="input-group-text border-0 px-2 ps-0 "
            onClick={() => {
              document.querySelector(".chatsearch").style.top = "-55px";
              document.querySelector(".chatsearch").style.opacity = "0";
              setSearchKey();
            }}
          >
            <i className="fa-solid fa-xmark cursor-pointer"></i>
          </span>
        </div>

        <p className="mb-2" style={{ fontWeight: "600" }}>
          Messages
        </p>
        <div className="d-flex ">
          <div className="search-box">
            <p
              className="search-btn mx-2 ms-0"
              onClick={() => {
                document.querySelector(".chatsearch").style.top = "0px";
                document.querySelector(".chatsearch").style.opacity = "1";
              }}
            >
              <BsSearch className="cursor-pointer" />
            </p>
          </div>
          <p className="mb-2 me-2">
            <AiOutlinePlusCircle
              style={{ fontSize: "18px" }}
              className="cursor-pointer"
              onClick={() => {
                setShowChat(true);
              }}
            />
          </p>
        </div>
      </div>
      {(!props.desRes || props.desRes.length === 0 || props.desRes?.data?.length === 0) && !showChat && (
        <div className="d-flex flex-column justify-content-center h-100 align-items-center  mt-5 pt-5 ">
          <div className="mb-3">
            <img style={{ height: "6rem" }} src={postBoxImg} alt="" srcSet="" />
          </div>
          <div className="text-align-center ">
            <div className="mb-0" style={{ fontSize: "24px" }}>
              No chats available
            </div>
            <div className="cl-grey1" style={{ fontSize: "16px" }}>
              Add leads to start a chat
            </div>
            <a
              href="#!"
              type="button"
              className="py-1 px-3 rounded-pill"
              style={{ border: "1px solid #3b5998", fontSize: "14px", color: "#3b5998" }}
              onClick={() => {
                setShowChat(true);
              }}
            >
              Start Chat
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoChatYet;
