// export const BASE_URL = "http://3.109.71.144:8000";
// export const BASE_URL = "http://anmol-VivoBook-15-ASUS-Laptop-X507UF:8002";
export const BASE_URL = "https://pro-api.idesign.market";

export const RAZOR_PAY_KEY = "rzp_live_ONeYKafSKSz1uF";
// export const RAZOR_PAY_KEY = "rzp_test_P2HRVMMubYwNBA";

export const STATUS_CODES = {
  SUCCESS: 200,
};
