import React from 'react';
import styles from './landingPageMobCss/section2mob.module.css';
import img1 from "../imagesMobImg/section2/mob1.png";
import file from "../imagesMobImg/section2/file.png";
import timelineee from "../imagesMobImg/section2/timeline.png";
import taskk from "../imagesMobImg/section2/task.png";
import note from "../imagesMobImg/section2/note.png";

const Section2Mob = () => {
  return (
    <>
     <div className={styles.container} style={{paddingLeft:"1.5rem",paddingRight:"1.5rem"}}>
      
        <div className={styles.content_div}>
         <div ><button className={styles.btn}>Project Management</button></div> 
          <div className={styles.heading}>
            Managing your Interior Project  was Never been this easy
          </div>
          <div className={styles.img_div}>
           <img src={img1} alt="" className={styles.mainImg}/>
             
        </div>
          <div className={styles.projects_div}>
            <div className={styles.file_timeline}>
              <div style={{textAlign:"center",width:"50%"}}>
                <img src={file} alt="" className={styles.icon}/>
                <div className={styles.icon_name}>FILES</div>
                <p className={styles.para}>Manage all project <br /> files in a  unified destination</p>
              </div>
              <div style={{textAlign:"center",width:'50%'}}>
                <img src={taskk} alt="" className={styles.icon}/>
               <div className={styles.icon_name}>TASK MANAGER</div> 
               <p className={styles.para}>Effortlessly manage <br /> your  interior design <br /> projects </p>
              </div>
          
            </div>
            <div className={styles.task_daily}>
            <div style={{textAlign:"center",width:'50%'}}>
                <img src={timelineee} alt="" className={styles.icon}/>
                <div className={styles.icon_name}>TIMELINES</div>
                <p className={styles.para}>Stay on track with your projects  from start to <br /> finish</p>
              </div>
              <div style={{textAlign:"center",width:'50%'}}>
                <img src={note} alt="" className={styles.icon}/>
               <div className={styles.icon_name}>DPR</div> 
               <p className={styles.para}>Collaborate effectively, <br /> stay on track</p>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    
    </>
  )
}

export default Section2Mob;