import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { HiPhone } from "react-icons/hi";
import img1 from "../SaasAssets/img1.svg";
import quo from "./imagesAnj/section3/quo.png";
import po from "./imagesAnj/section3/po.png";
import vendor from "./imagesAnj/section3/vendor.png";
import acc from "./imagesAnj/section3/accounts.png";
// import img2 from "./imagesAnj/section3/Sec2.png";
// import back from "./imagesAnj/section3/back.png";

import { toast } from "react-hot-toast";
import styles from "./landingPageCss/landingSection3.module.css";
const LandingSection3 = () => {
  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const [isSubmit, setSubmit] = useState(true);
  const handleModal = () => {
    setSubmit(true);
    handleClose();
  };

  const [name, setName] = useState();
  const [phNum, setphNum] = useState();

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const handleChange1 = (e) => {
    if (e.target.value.length <= 10) setphNum(e.target.value);
  };
  const submitContact = async () => {
    if (name?.length > 0 && phNum?.length > 0) {
      const response = await axios
        .post(
          `https://pro-api.idesign.market/user/contact-us`,
          {
            name: name,
            phoneNumber: phNum,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: authTok,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setSubmit(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please fill all the fields");
    }
  };

  return (
    <>
        <div className={styles.container}>
      <div style={{display:'flex',paddingLeft:"6rem"}}>
        <div className={styles.content_div}>
            <div > <button className={styles.btn}>#Cost Management</button></div>
         
          <div className={styles.heading}>
          Efficiently manage your finances, unleash  <br /> your creativity
          </div>
          <div className={styles.projects_div}>
            <div className={styles.file_timeline}>
              <div style={{width:"50%",display:'flex',flexDirection:'column'}}>
                <img src={quo} alt="" className={styles.icon}/>
                <div className={styles.icon_name}>QUOTATION</div>
                <p className={styles.para}>Create Item masters, share, get approvals and track all changes</p>
              </div>
              <div style={{width:"65%",paddingLeft:'6rem'}}>
                <img src={po} alt="" className={styles.icon}/>
                <div className={styles.icon_name}>PURCHASE ORDER</div>
                <p className={styles.para}>Create purchase order from quotation, track extra items and margins.</p>
              </div>
            </div>
            <div className={styles.task_daily}>
              <div style={{width:"50%"}}>
                <img src={acc} alt="" className={styles.icon}/>
               <div className={styles.icon_name}>ACCOUNTS</div> 
               <p className={styles.para}>Create instant project level ledgers with payment triggers</p>
              </div>
              <div style={{paddingLeft:'6rem',width:"65%"}}>
                <img src={vendor} alt="" className={styles.icon}/>
               <div className={styles.icon_name}>VENDOR MANAGEMENT</div> 
               <p className={styles.para}>Create instant project level ledgers with payment triggers</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.img_div}>
          <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/back.png" alt="" className={styles.back}/>
           <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Frame%202611059.svg" alt="" className={styles.mainImg}/>
                     
        </div>
        </div>
      </div>
    </>
  )
};

export default LandingSection3;
