import React from "react";

const CaseStudy = () => {
  return (
    <>
      <div style={{marginBottom: "7rem" }}>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <div style={{ color: "#1D3557", fontFamily: "Inter", fontSize: "36px", fontWeight: 700 }}>Case Studies</div>
          <div
            style={{
              color: "#252525",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            Harnesses stronger business intelligence with iDesign Pro
          </div>
        </div>

        <div
          style={{
            background: "#F1FAFD",
            display: "flex",
            justifyContent: "center",
            display: "flex",
            justifyContent: "center",
            gap: "4rem",
            marginTop: "1rem",
            paddingTop: "4rem",

            paddingLeft:"4rem",paddingRight:'4rem',
            paddingBottom: "4rem",
          }}
        >
          <div style={{ background: "#ffffff", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px",width:"30%"}}>
            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207483.png" alt="" style={{width:"100%"}}/>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#ffffff", padding: "1rem", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
              <p style={{ color: "#000000", fontFamily: "Inter", fontWeight: 500, fontSize: "18px",lineHeight:"24px",textAlign:'center' }}>
                Design/Build firm grows profitability and referrals with iDesign app-suite.
              </p>
              <div style={{ color: "#128FAE", fontWeight: 600,cursor:"pointer",marginTop:"2rem"}} >
                <a href="https://blog.idesign.market/" target="_blank" style={{textDecoration:"none",color: "#128FAE",fontWeight: 600,cursor:"pointer"}}> View More <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/arrow_insert.png" alt="" /></a>
             
              </div>
            </div>
          </div>

          <div style={{ background: "#ffffff", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px",width:"30%"}}>
            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207484.png" alt="" style={{width:"100%"}}/>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#ffffff", padding: "1rem", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px"}}>
              <p style={{ color: "#000000", fontFamily: "Inter", fontWeight: 500, fontSize: "18px",lineHeight:"24px" ,width:"100%",textAlign:"center"}}>
              Fit out construction firm reduces material waste with iDesign's task management module
              </p>
              <div style={{ color: "#128FAE", fontWeight: 600,cursor:"pointer",marginTop:"1rem"}} >
                <a href="https://blog.idesign.market/" target="_blank" style={{textDecoration:"none",color: "#128FAE",fontWeight: 600,cursor:"pointer"}}> View More <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/arrow_insert.png" alt="" /></a>
             
              </div>
            </div>
          </div>

          <div style={{ background: "#ffffff", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px",width:"30%" }}>
            <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207485.png" alt="" style={{width:"100%"}}/>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#ffffff", padding: "1rem", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
              <p style={{ color: "#000000", fontFamily: "Inter", fontWeight: 500, fontSize: "18px",lineHeight:"24px",textAlign:'center' }}>
              Design build firm enables remote workforce with on-demand access to plans and decisions
              </p>
              <div style={{ color: "#128FAE", fontWeight: 600,cursor:"pointer",marginTop:"1rem"}} >
                <a href="https://blog.idesign.market/" target="_blank" style={{textDecoration:"none",color: "#128FAE",fontWeight: 600,cursor:"pointer"}}> View More <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/arrow_insert.png" alt="" /></a>
             
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default CaseStudy;
