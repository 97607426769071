import React from "react";
import styles from "../onBoardingCss/personalDetail.module.css";
import icon from "../footerImg/file/Vector.svg";
import icon1 from "../footerImg/task/Vector.png";
import icon2 from "../footerImg/reports/Vector.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import MuiPhoneNumber from "material-ui-phone-number";
import { Country, State, City } from "country-state-city";
import { TextField, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import idesign from "../../NewLandingPage/imagesAnj/logo.png";
import { toast } from "react-hot-toast";
import { constants } from "../../../../../constants";

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$/;

const checkIfHasOnlyNumbers = (value) => {
  if (!isNaN(value) || value === "") {
    return true;
  } else {
    return false;
  }
};
const numberArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const PersonalDetails = () => {
  const today = new Date().toISOString().split('T')[0];
  const [cityForMap, setCityForMap] = useState("Delhi")
  const [phoneCode, setPhoneCode] = useState("us");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [particularCountryData, setParticularCountryData] = useState({});
  const [dropDown, setdropDown] = useState("");
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState({
    fname: false,
    lName: false,
    address: false,
    city: false,
    zipCode: false,
    birth: false,
    number: false,
    email: false,
    country: false,
  });
  // const [input, setinput] = useState({
  //   fname: "",
  //   profession: "",
  //   email: "",
  //   city: "",
  //   number: "",
  // });
  const [errormsg, setErrorMsg] = useState("");

  const [input, setinput] = useState({
    fname: "",
    lName: "",
    address: "",
    city: "",
    zipCode: "",
    birth: "",
    number: "",
    country:"",
    email:""
  });

  const [errorMsgs, setErrorMsgs] = useState({
    fNameErr: "",
    lNameErr: "",
    numberErr: "",
  })

  const [phoneNumberErr, setPhoneNumberErr] = useState('');

  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";
  const postData = async () => {
    // const payload = new FormData();
    // payload.append("firstName", input.fname);
    // payload.append("email", input.email);
   // payload.append("type", dropDown);
    // payload.append("city", input.city);
    // payload.append("phoneNumber", input.number);
    // payload.append("pinCode", input.zipCode);
    // payload.append("country", input.country);
    // payload.append("lastName", input.lName);
    // payload.append("address", input.address);
const data={
  "firstName": input.fname,
  "email": input.email,
  "city": input.city,
  "phoneNumber": input.number,
  "pinCode": input.zipCode,
  "country": input.country,
  "lastName": input.lName,
  "address": input.address,
}
    await axios
      .post(`${constants.B2B_BASE}/user/updateProfile`, data, { 
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (res) {
      //  handleOnboarding();
        navigate("/company")
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //disable browser back btn
  useEffect(() => {
    function preventBack() { 
      window.history.forward();  
    } 
    
    setTimeout(preventBack(), 0); 
    
    window.onunload = function() { return null; }; 
  }, []);


  // const handleInput = (event) => {
  //   const { name, value } = event.target;
  //   if (name === "number") {
  //     if (value.length < 11) {
  //       setinput((prev) => {
  //         return {
  //           ...prev,
  //           number: value,
  //         };
  //       });
  //     }
  //   } else {
  //     if (value.length < 50) {
  //       setinput((prev) => {
  //         return {
  //           ...prev,
  //           [name]: value,
  //           profession: dropDown,
  //         };
  //       });
  //     }
  //   }
  // };

  // const handleOnboarding = async () => {
  //   await axios
  //     .post(
  //       `${constants.B2B_BASE}/user/edit-user-details?userId=${localStorage.getItem("userId")}`,
  //       {
  //         isOnboardingDone: true,
  //       }
  //     )
  //     .then(function (res) {
  //       // localStorage.removeItem("emailId")
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleInput = (event) => {
    const { name, value } = event.target;
    const alphaValue = value.replace(/[^a-zA-Z]/g, '');
    const numValue=value.replace(/[^0-9]/g, "");
    
    if (name === "zipCode") {
      if(input.country=="India"){
          if (value.length < 7) {
          setinput((prev) => {
            return {
              ...prev,
              zipCode: numValue,
            };
          });
          setError((prev) => {
            return {
              ...prev,
              zipCode: false,
            };
          });
        }
      }
    else{
        if (value.length < 10) {
        setinput((prev) => {
          return {
            ...prev,
            zipCode: numValue,
          };
        });
        setError((prev) => {
          return {
            ...prev,
            zipCode: false,
          };
        });
    }
    }
    }
    
    if (name === "fname") {
      if (value.length < 24) {
        setinput((prev) => {
          return {
            ...prev,
            fname: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
        setError((prev) => {
          return {
            ...prev,
            fname: false,
          };
        });
      }  
      if(value.length>=24){
        setErrorMsgs((prev) => {
          return { ...prev, fNameErr: "Max character limit has been reached" };
        });
      }
      else if(value.length<=24){
        setErrorMsgs((prev) => {
          return { ...prev, fNameErr: "" };
        });
      }    
    }
    if (name === "lName") {
      if (value.length < 24) {
        setinput((prev) => {
          return {
            ...prev,
            lName: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
        setError((prev) => {
          return {
            ...prev,
            lName: false,
          };
        });
      }    
      if(value.length>=24){
        setErrorMsgs((prev) => {
          return { ...prev, lNameErr: "Max character limit has been reached" };
        });
      }
      else if(value.length<=24){
        setErrorMsgs((prev) => {
          return { ...prev, lNameErr: "" };
        });
      }  
    }

    if (name === "address") {
        const words = value.trim().split(/\s+/);
        if (words.length <= 20) {
          setinput((prev) => {
                return {
                  ...prev,
                  address: value.charAt(0).toUpperCase() + value.slice(1),
                };
              });
    }
  }
  
    if (name === "city") {
      if (value?.length < 32) {
        setinput((prev) => {
          return {
            ...prev,
            city: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
      }
      // let check = numberArray.some((curElem) => {
      //   return value.includes(curElem);
      // });
      // if (!check) {
      //   if (value.length < 20) {
      //     setinput((prev) => {
      //       return {
      //         ...prev,
      //         city: value.charAt(0).toUpperCase() + value.slice(1),
      //       };
      //     });
      //   }
      // }
    }
setCityForMap(input.city);
    if (name === "birth") {
      setinput((prev) => {
        return {
          ...prev,
          birth: value,
        };
      });
    }

    if (name === "number") {
        setinput((prev) => {
          return {
            ...prev,
            number: value,
          };
        });
       
        if (selectedCountry === 'IN') { 
          if (value.length < 10) {
              setPhoneNumberErr('yes err');
              setError((prev) => {
                return {
                  ...prev,
                  number: true,
                };
              });
          } else {
              setPhoneNumberErr('');
              setError((prev) => {
                return {
                  ...prev,
                  number: false,
                };
              });
          }
      } 
    }
   
    if (name === "email") {
      let x = emailRegex.test(value);
      if (!x) {
        setErrorMsg("Invalid Email");
      } else{
        setErrorMsg("");
      }
      setinput((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
      setError((prev) => {
        return {
          ...prev,
          email: false,
        };
      });
      }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const countryData = await Country.getAllCountries();
      setCountries(countryData);
      // const initialCountryIsoCode = "IN"; 
      // setSelectedCountry(initialCountryIsoCode);
    };
    fetchCountries();
  }, []);

useEffect(()=>{
  localStorage.setItem("countryCode", "US");
  localStorage.setItem("city", cityForMap)
},[])

  const getParticularCountryData = async (countryCode) => {
    const data = await Country.getCountryByCode(countryCode);
    setinput((prev) => {
      return {
        ...prev,
        country: data?.name,
      };
    });
    setCurrency(data.currency)
    setPhoneCode(data?.isoCode.toLowerCase())
    setParticularCountryData(data);
  };

  const handleCountryChange = async (countryCode) => {
    setSelectedCountry(countryCode);
    localStorage.setItem("countryCode", countryCode);
  };

  const [getData, setgetData] = useState([]);

  const GetName = async () => {
    await axios
      .get(`${constants.B2B_BASE}/user/profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (response) {
        console.log(response?.data?.data);
        setgetData(response?.data?.data);
        setUserName(response?.data?.data?.fullName)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log(getData);
  useEffect(() => {
    GetName();
  }, []);

  useEffect(() => {
    if (getData) {
      let x = input;
      // let fullName= getData?.companyName;
      var fullName = localStorage.getItem("initialName");
      var parts = fullName.split(" ");
      var firstName = parts[0];
      var lastName = parts.slice(1).join(" ");
      x["fname"]=firstName;
      x["lName"]=lastName;
      // x["fname"] = getData?.companyName;
      // x["profession"]=getData?.companyType;
      x["email"] = getData?.email;
      x["city"] = getData?.city;
      x["number"] = getData?.phoneNumber;
      // dropDown=object.assign([],getData?.companyType);
      setinput({ ...x });
    }
  }, [getData]);
  // console.log(dropDown)
  // fullname validation
  const [fullnameErr, setfullnameErr] = useState("notActive");
  const fullnameError = () => {
    if (input.fname === "") {
      setfullnameErr("Active");
    } else {
      setfullnameErr("");
    }
    if (
      input.fname !== "" &&
      input.profession !== "" &&
      input.number !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };

  //profession
  const [professErr, setprofessErr] = useState("notActive");
  const professionError = () => {
    if (dropDown === "") {
      setprofessErr("Active");
    } else {
      setprofessErr("");
    }
    if (
      input.fname !== "" &&
      dropDown !== "" &&
      input.number !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };

  // phone no. validation
  const [phoneErr, setphoneErr] = useState("notActive");
  const phoneNumberError = () => {
    if (input.number === "") {
      setphoneErr("Active");
    } else {
      setphoneErr("");
    }
    if (
      input.fname !== "" &&
      input.profession !== "" &&
      input.number !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };

  // email validate
  const [emErr, setemErr] = useState("notActive");
  const EmailError = () => {
    if (input.email === "") {
      setemErr("Active");
    } else if (emailErr !== "") {
      setEmailErr("Invalid Email");
    } else {
      setemErr("");
      setEmailErr("");
    }
    if (
      input.fname !== "" &&
      input.profession !== "" &&
      input.number !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };

  // city validation
  const [cityErr, setcityErr] = useState("notActive");
  const cityError = () => {
    if (input.city === "") {
      setcityErr("Active");
    } else {
      setcityErr("");
    }
    if (
      input.fname !== "" &&
      input.profession !== "" &&
      input.number !== "" &&
      input.city !== "" &&
      input.email !== "" &&
      !emailErr
    ) {
      navigate("/company");
    }
  };
  //   email validation
  const [emailErr, setEmailErr] = useState("");
  const emailValidation = () => {
    return /\S+@\S+\.\S+/.test(input.email);
  };

  useEffect(() => {
    const mailE = emailValidation();
    if (!mailE) {
      setEmailErr("Invalid Email");
    } else {
      setEmailErr("");
    }
  }, [input.email]);


  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
        <div className={styles.logoImgContainer}><img src={idesign} alt="" className={styles.logoImg} /></div>
        <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
              margin:"auto auto",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}><img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>FILE MANAGER</div></div>
                  <div className={styles.para}>Seamlessly upload files and glide through a structured file hierarchy with ease, ensuring your documents are always organised and accessible.</div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.amazonaws.com/NO_COMPANYNAME/Edit%20location%20%26%20description.png" alt="First slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                   <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>TASK MANAGEMENT</div>
                  </div>
                  <div className={styles.para}>
                  Monitor team progress with Task Manager screen and effortlessly create, assign and update tasks. Also generate detailed reports for informed decision-making.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Table%20v2-1.png" alt="Second slide" className={styles.imgg} />
              </div>
             
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt="" className={styles.miniImg}
                />
                  <div className={styles.heading}>Quotation</div>
                  </div>
                  <div className={styles.para}>
                  Effortlessly create quotations from an item library. Compare and manage quotations efficiently. Streamline budget creation and purchase orders.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Create%20Quotation.png" alt="Third slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>Timeline</div>
                  </div>
                  <div className={styles.para}>
                  Easily create and allocate hours with Timesheets. Track project progress with multiple views. Create detailed timelines with predecessors, subtasks, and checklists.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/For%20onb%201.png" alt="Third slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                </div>
            </Carousel.Item>
          </Carousel>  
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            <div className={styles.personalDetailsDiv} >

              <div className={styles.personal_head}>Personal Details</div>

              {/* <button className={styles.backBtn} onClick={() => {
                    navigate("/profilebuild")
              }}>
               <AiOutlineArrowLeft className={styles.backArrow} />
                Go Back
              </button> */}
              {/* <div className={styles.skipShowHide}>
              <a onClick={()=>localStorage.removeItem("cameFrom")} href={"https://www.idesign.market/"} className={styles.skip} style={{ textDecoration: "none" }}>
              Skip
            </a>
              </div> */}
            </div>

            {/* all inputs div */}
            <div style={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
              {/* <div style={{ display: "flex", gap: "1rem" }}> */}
              <div className={styles.nameWrapper} >
              <div style={{display:"flex", flexDirection:"column", gap:"20px", width:"100%"}}>
                <TextField
                  id="outlined-basic"
                  error={error.fname}
                  label="First Name*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="fname"
                  value={input.fname}
                  onChange={handleInput}
                  // style={{ width: "50%", }}
                  sx={{ width: "100%",  '@media (max-width: 600px)': { width: '100%'}}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
             {(errorMsgs.fNameErr!=="")?<div style={{color:'red', fontSize:"10px"}}>{errorMsgs.fNameErr}</div>:""}
                </div>
                <div style={{display:"flex", flexDirection:"column", gap:"20px", width:"100%"}}>
                <TextField
                  id="outlined-basic"
                  error={error.lName}
                  label="Last Name*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="lName"
                  value={input.lName}
                  onChange={handleInput}
                  // style={{ width: "50%", }}
                  sx={{ width: "100%",  '@media (max-width: 600px)': { width: '100%'}}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
             {(errorMsgs.lNameErr!=="")?<div style={{color:'red', fontSize:"10px"}}>{errorMsgs.lNameErr}</div>:""}
                </div>
              </div>
              <div style={{display:"flex", flexDirection:"column", gap:"20px", }}>
              <TextField
                  id="outlined-basic"
                  error={error.email}
                  label="Email*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="email"
                  value={input.email}
                  onChange={handleInput}
                  fullWidth
                  style={{ borderRadius: "12px" }}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                />
              {(errormsg.length>0)?<div style={{color:'red', fontSize:"10px"}}>{errormsg}</div>:""}
              </div>

              <div style={{ marginTop: (errormsg.length > 0) ? "-30px" : "0px" }}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="address"
                  value={input.address}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
              </div>

              {/* <div style={{ display: "flex", gap: "1rem", position: "relative" }}> */}
              <div className={styles.cityContainer}>
                <FormControl sx={{borderRadius: "12px", width:"33%",'@media (max-width: 600px)': { width: '100%'} }} >
                          <InputLabel id="demo-simple-select-helper-label" >Country</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedCountry} 
                            label="Country"
                             onChange={(e) => {
                                      handleCountryChange(e.target.value);
                                      getParticularCountryData(e.target.value);
                                    }}
                            sx={{borderRadius: "12px",'@media (max-width: 600px)': { width: '100%'} }}
                          >
                              {/* <MenuItem value="">
                              <em>None</em>
                            </MenuItem> */}
                                        {countries.map((country) => (
                                  <MenuItem key={country.isoCode} value={country.isoCode}>
                                    {country.name}
                                  </MenuItem>
                                     ))}
                          </Select>                        
                        </FormControl>

                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="city"
                  value={input.city}
                  onChange={handleInput}
                  // style={{ borderRadius: "12px", width: "50%" }}
                  sx={{ width: "33%", '@media (max-width: 600px)': { width: '100%', marginBottom:"1.6rem"}}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  // inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />

                <TextField
                  id="outlined-basic"
                  // label="Pin Code"
                  label = {input.country=="India" ? "Pin Code" : "Zip Code"}
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="zipCode"
                  value={input.zipCode}
                  onChange={handleInput}
                  // style={{ width: "50%" }}
                  sx={{ width: "33%", '@media (max-width: 600px)': { width: '100%'}}}
                  // onKeyDown={handleNumberInput}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
                {/* <div style={{ position: "absolute", height: "3rem", width: "2rem", right: "1.2%", top: "3%", background: "#ffffff" }}></div> */}
              </div>

              <div className={styles.birthContainer}>
                <TextField
                  id="outlined-basic"
                  label="Date of Birth"
                  variant="outlined"
                  className="saas-form"
                  type="date"
                  name="birth"
                  value={input.birth}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", '@media (max-width: 600px)': { width: '100%'} }}
                  InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }} // Removes mm/dd/yy placeholder
                  inputProps={{ max: today }}
                />
                 <MuiPhoneNumber
                  error={error.number}
                  // helperText={phoneNumberErr}
                  fullWidth
                  variant="outlined"
                  label="Phone number*"
                  value={input.number}
                  onChange={(newVal) => handleInput({ target: { name: "number", value: newVal }})}
                  defaultCountry={phoneCode}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px", '@media (max-width: 600px)': { width: '100%'}} }}
                  // inputProps={{style:{ padding:isMobile ? "padding: 18px 20px 18px 0px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
              </div>

            </div>

            <div>
              <button
                className={styles.nextBtn}
                style={{
                  // marginBottom:"2rem",
                zIndex:"999"}}
                onClick={() => {
                  Object.keys(input).forEach((curElem) => {
                    if (input[curElem]) {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: false,
                        };
                      });
                    } else {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: true,
                        };
                      });
                    }
                  });
                  if (input.email !== "" && input.fname !== "" && input.lName !== "" && input.number !== "") {
                    // postData();
                    if (errormsg.length>0) {
                        toast.error(errormsg);
                      }else if(input.number.length<=0){
                        toast.error("Enter phone number")
                      }
                      else{
                        if(phoneNumberErr.length===0){
                          if(input.city!==""){
                          localStorage.setItem("cityName", input.city)
                        }
                        localStorage.setItem("pincodeValue",input.zipCode)
                        postData()
                      }
                      }

                  }
                }}
              >
                Next
              </button>
              <div></div>
            </div>
                <div className={styles.skipBtnInLast}>
            <a href={"/company"} className={styles.skip} style={{ textDecoration: "none" }} 
            // onClick={()=>handleOnboarding()}
            >
              Skip Personal Profile
            </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;