import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import idesign from './SaasAssets/idesign.png'
import idesign from "./NewLandingPage/imagesAnj/logo.png";
import { BiChevronDown } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ScheduleADemo from "./Products/ScheduleADemo";
import { FiChevronDown } from "react-icons/fi";
import { useRef } from "react";
import { featureScroll } from "../../Actions";
import { constants } from "../../../constants";
// import "../SaasOnboarding/"

// import { Direction } from "react-toastify/dist/utils";

const LoginHeader = (props) => {
  const [changeBorder, setchangeBorder] = useState("");
  const dispatch = useDispatch();
  const [changeComp, setchangeComp] = useState("find");
  const navigate = useNavigate();
  const [show11, setShow11] = useState(false);
  const handleShow = () => navigate("/schedule");
  const handleClose = () => setShow11(false);
  const path = useLocation();

  // console.log(path)

  const handleSignIn = () => {
    localStorage.setItem("cameFrom", "b2b");
    window.location.assign(`${constants.PRO_URL}`);
  };
  const handleSignup = () => {
    navigate("/signup");
  };

  const profileData = useSelector((state) => state.addToCartReducer.profileData);
  // console.log(profileData)

  useEffect(() => {
    if (localStorage.getItem("pathname") === null) {
      if (localStorage.getItem("token") != "null" && localStorage.getItem("token") != null) {
        // navigate("/lead");
        // setIsLoggedIn(true)
      }
    } else {
      const path = localStorage.getItem("pathname");
      // navigate(`${path}`);
    }
  }, []);

  const [box, setbox] = useState("All");
  const [box1, setbox1] = useState("find");

  const [activeTab, setactiveTab] = useState("notact");
  const location = useLocation();

  const [dropdownOpen, setdropdownOpen] = useState("");

  // scroll to top
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  //  redirct func
  // const scrollToDiv=(id)=>{
  //   const targetDiv = document.getElementById(id);
  //   const offset = 150;
  //   const targetOffset = targetDiv.offsetTop - offset;

  //   window.scrollTo({
  //     top: targetOffset,
  //     behavior: 'smooth',
  //   });
  // }

  // useEffect(() => {
  //   scrollToDiv(localStorage.getItem("features"));
  // }, [])

  return (
    <>
      <ScheduleADemo show={show11} showFunc={handleShow} closeFunc={handleClose} />

      <div
        style={
          path.pathname === "/landingpage" ||
          path.pathname === "/" ||
          path.pathname === "/products" ||
          path.pathname === "/pricing" ||
          path.pathname === "/architect" ||
          path.pathname === "/design" ||
          path.pathname === "/contractor" ||
          path.pathname === "/estate" ||
          path.pathname === "/retail"
            ? {
                top: "0",
                height: "10vh",
                boxShadow: " 0px 4px 12px rgba(0, 0, 0, 0.1)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                background: "white",
                position: "sticky",
                top: "0px",
                borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
                zIndex: "1500",
              }
            : {
                height: "10vh",
                position: "sticky",
                top: "0px",
                boxShadow: " 0px 4px 12px rgba(0, 0, 0, 0.1)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
                zIndex: "1500",
              }
        }
      >
        {path.pathname === "/landingpage" ||
        path.pathname === "/" ||
        path.pathname === "/schedule" ||
        path.pathname === "/products" ||
        path.pathname === "/about" ||
        path.pathname === "/contact" ||
        path.pathname === "/architect" ||
        path.pathname === "/design" ||
        path.pathname === "/contractor" ||
        path.pathname === "/estate" ||
        path.pathname === "/retail" ? (
          // path.pathname === "/pricing"
          <div className="d-flex justify-content-between" style={{ width: "92%", height: "10vh",position:"fixed",width:"100%",background:"#ffffff",boxShadow:"0 4px 10px #dfdfdf"}}>
            <img
              src={idesign}
              alt=""
              style={{
                width: "12rem",
                zIndex: "2",
                cursor: "pointer",
                objectFit: "contain",
                height: "100%",
              }}
              onClick={() => {
                scrollToTop();
                navigate("/");
              }}
            />
            <div className="d-flex justify-content-between align-items-center gap-5">
              <div
                className={changeBorder === "fea" ? "head-pricing-active" : "head-pricing"}
                onClick={() => {
                  navigate("/products");
                  setchangeBorder("fea");
                }}
              >
                Features
              </div>
              <div
              // className={
              //   changeBorder === "prod"
              //     ? "head-pricing-active"
              //     : "head-pricing"
              // }
              >
                {/* Who we are */}
                <Dropdown show={dropdownOpen === "solution"} >
                  <Dropdown.Toggle className="px-0" onMouseEnter={() => setdropdownOpen("solution")}>
                    <span
                      style={{ color: "#000000", fontWeight: "600", fontSize: "16px", lineHeight: "30px", color: "black", display: "flex", alignItems: "center", cursor: "pointer", height: "100%" }}
                      onClick={() => setchangeBorder("prod")}
                    >
                      Who we Serve
                    </span>

                    {/* <FiChevronDown
                      style={{ marginLeft: "0.5rem", color: "black" }}
                    /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      width: "23rem",
                      border: "none",
                      borderRadius: "4px",
                      backgroundColor: "#EFF3F9",
                    }}
                    onMouseLeave={() => setdropdownOpen("")}
                    className="drop_menu"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        gap: "0.7rem",
                      }}
                    >
                      <div className="Find_comm_div">
                        <div
                          className={box === "arc" ? "find_div" : "find_div_normal"}
                          onMouseOver={() => setbox("arc")}
                          onClick={() => {
                            navigate("/architect");
                          }}
                        >
                          <div className="find_head">Architects</div>
                          <p className="findd_para">Streamline your Design Process to Enhance Efficiency and Profitability</p>
                        </div>

                        <div
                          className={box === "con" ? "find_div" : "find_div_normal"}
                          onMouseOver={() => setbox("con")}
                          onClick={() => {
                            navigate("/contractor");
                          }}
                        >
                          <div className="find_head">Construction Companies</div>
                          <p className="findd_para">Efficiently Manage Projects and Teams</p>
                        </div>

                        <div
                          className={box === "des" ? "find_div" : "find_div_normal"}
                          onMouseOver={() => setbox("des")}
                          onClick={() => {
                            navigate("/design");
                          }}
                        >
                          <div className="find_head">Design & Build</div>
                          <p className="findd_para">Manage Design to Construction with intuitive features for the project journey</p>
                        </div>

                        <div
                          className={box === "estate" ? "find_div" : "find_div_normal"}
                          onMouseOver={() => setbox("estate")}
                          onClick={() => {
                            navigate("/estate");
                          }}
                        >
                          <div className="find_head">Real Estate Builders</div>
                          <p className="findd_para">Efficiently Manage Projects and Teams</p>
                        </div>

                        <div
                          className={box === "retail" ? "find_div" : "find_div_normal"}
                          onMouseOver={() => setbox("retail")}
                          onClick={() => {
                            navigate("/retail");
                          }}
                        >
                          <div className="find_head">Retail Companies</div>
                          <p className="findd_para">Get a complete overview of your stores with dynamic updates</p>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/* <div
                className={
                  location.pathname === "/products"
                    ? "head-pricing-active"
                    : "head-pricing"
                }
                onClick={() => {
                  navigate("/products");
                  window.scroll(0, 0);
                }}
              >
                Products
              </div> */}
              {/* <div
                className={
                  location.pathname === "/pricing"
                    ? "head-pricing-active me-4"
                    : "head-pricing me-4"
                }
                onClick={() => {
                  navigate("/pricing");
                  window.scroll(0, 0);
                }}
              >
                Pricing
              </div> */}
              {/* <div
                className="head-pricing me-4"
                onClick={() => {
                  window.location.assign(
                    "https://www.idesign.market/community/"
                  );
                  setactiveTab("act");
                }}
              >
                Community
              </div> */}
              {/* <div
                className={changeBorder==="maga"?"head-pricing-active":"head-pricing"}
                onClick={() => {
                  window.location.assign("https://magazine.idesign.market/");
                  setchangeBorder("maga");
                }}
              >
                Magazine
              </div> */}

              <div
                className={changeBorder === "abou" ? "head-pricing-active" : "head-pricing"}
                onClick={() => {
                  navigate("/about");
                  setchangeBorder("abou");
                }}
              >
                Team
              </div>

              <div
                className={changeBorder === "contac" ? "head-pricing-active" : "head-pricing"}
                onClick={() => {
                  navigate("/contact");
                  setchangeBorder("contac");
                }}
              >
                Contact
              </div>
                  {/* <div
                className={
                  location.pathname === "/pricing"
                    ? "head-pricing-active me-4"
                    : "head-pricing me-4"
                }
                onClick={() => {
                  localStorage.setItem("pricing", true)
                  localStorage.setItem("cameFrom", "b2b");
                  navigate("/pricing");
                  window.scroll(0, 0);
                }}
              >
                Pricing
              </div> */}
              {/* moreeeeeeeeeeeeeeeeeeee */}
              {/* <div
                className={
                  changeBorder === "Mor"
                    ? "head-pricing-active"
                    : "head-pricing"
                }
                onClick={() => {
                  setchangeBorder("Mor");
                }}
              >
                <Dropdown show={dropdownOpen === "more"}>
                  <Dropdown.Toggle
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                    className="px-0"
                    onMouseEnter={() => setdropdownOpen("more")}
                  >
                    <span style={{ color: "#000000" }}>More</span>
                    <FiChevronDown
                      style={{ marginLeft: "0.5rem", color: "black" }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      width: "45rem",
                      border: "none",
                      borderRadius: "4px",
                      backgroundColor: "#EFF3F9",
                    }}
                    onMouseLeave={() => setdropdownOpen("")}
                    className="drop2_menu"
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.5rem",
                        // paddingRight: "0.5rem",
                        gap: "0.7rem",
                        // border:'2px solid red'
                      }}
                    >
                      <div className="Find_comm_divProf">
                        <a
                          href="https://www.idesign.market/find-professionals"
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <div
                            className={
                              box1 === "find"
                                ? "find_divProf"
                                : "find_div_normalProf"
                            }
                            onMouseOver={() => setbox1("find")}
                          >
                            <div className="find_head">Find Professionals</div>
                            <p className="findd_para">
                              Unleash Your Creative Potential : Seamlessly
                              Streamline Your Interior Design Process
                            </p>
                          </div>
                        </a>

                        <a
                          href="https://www.idesign.market/community/"
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <div
                            className={
                              box1 === "comm"
                                ? "find_divProf"
                                : "find_div_normalProf"
                            }
                            onMouseOver={() => setbox1("comm")}
                            onClick={() => setchangeComp("comm")}
                          >
                            <div className="find_head">Community</div>
                            <p className="findd_para">
                              Streamline Your Construction Process: Enhance
                              Efficiency and Profitability
                            </p>
                          </div>
                        </a>
                      </div>
                    

                      {box1 === "find" ? (
                        <div className="Company_type_div">
                          <a
                            href="https://www.idesign.market/find-professionals/interior-designers"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            className="des_div"
                          >
                            <img
                              src="https://idesign-office.s3.amazonaws.com/shelves.svg"
                              alt=""
                            />
                            <span className="enterprise_head">
                              Interior Designer
                            </span>
                          </a>

                          <a
                            href="https://www.idesign.market/find-professionals/modular-kitchens"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            className="des_div"
                          >
                            <img
                              src="https://idesign-office.s3.ap-south-1.amazonaws.com/pallet.svg"
                              alt=""
                            />
                            <span className="enterprise_head">
                              Modular Factory
                            </span>
                          </a>

                          <a
                            href="https://www.idesign.market/find-professionals/contractors"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            className="des_div"
                          >
                            <img
                              src="https://idesign-office.s3.ap-south-1.amazonaws.com/handyman.svg"
                              alt=""
                            />
                            <span className="enterprise_head">Contractor</span>
                          </a>
                        </div>
                      ) : (
                        <div
                          style={{ width: "62%", height: "13rem" }}
                          className="comm_imge"
                        >
                          <a
                            href="https://www.idesign.market/community/"
                            target="_blank"
                          >
                            <img
                              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Frame%202611639%20%283%29.svg"
                              alt=""
                              className="comm_img"
                            />
                          </a>
                        </div>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </div>

            <div style={{ display: "flex", alignItems: "center", height: "100%", gap: "1rem" }}>
              <button className="header-signn" onClick={handleSignIn}>
                Login
              </button>
              {/* <button className="header-sign2" onClick={handleSignup}>
                Sign Up
              </button> */}
              <button className="header-sign ms-3" style={{ width: "10rem", height: "2.5rem", border: "none" }} onClick={handleShow}>
                Schedule a demo
              </button>
            </div>
          </div>
        ) : (
          <img
            src={idesign}
            alt=""
            style={{ width: "11rem", zIndex: "1", cursor: "pointer" }}
            onClick={() => {
              navigate("/");
              window.scroll(0, 0);
            }}
          />
        )}
      </div>
    </>
  );
};

export default LoginHeader;
