// import Router from "./Router";
import { store, persistor } from "./Redux/store";
import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import { toast } from "react-hot-toast";
// console.log("saflkjnpm ")
// function App() {
//   return (
//     <Provider store={store}>
//       <PersistGate persistor={persistor}>
//         <Router />
//           transition={Slide}
//           hideProgressBar
//           position={toast.POSITION.BOTTOM_RIGHT}
//           autoClose={3000}
//         />
//       </PersistGate>
//     </Provider>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import Comp1 from "./Components/Comp1";
import Comp2 from "./Components/Comp2";
import DesignStyles from "./Components/DesignStyles";

import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import ProjectsNumber from "./Components/ProjectsNumber";
import Consultation from "./Components/Consultation";
import DesignFee from "./Components/DesignFee";
import PerRoomCharge from "./Components/PerRoomCharge";
import AddProjects from "./Components/AddProjects";
import AddProjectPics from "./Components/AddProjectPics";
import SaveOrEditProject from "./Components/SaveOrEditProject";
import ProjectSummary from "./Components/ProjectSummary";
import Plans from "./Pages/Dashboard/Plans/index";
import Leads from "./Pages/Dashboard/Leads/index";
import Home from "./Pages/Home/index";
import ProfileBuild from "./Pages/Auth/ProfileBuild";
import Congratulations from "./Components/Congratulations";
import Checkout from "./Pages/Dashboard/Checkout";
import Profile from "./Pages/Dashboard/MyProfile/index";
import Terms from "./Pages/Terms/index";
import Policy from "./Pages/PrivacyPolicy/index";
import HomePage from "./Lms/HomePage";

import { OnboardingContext } from "./Context/Context";
import Products from "./Pages/Products/index";
import Pricing from "./Pages/Pricing/index";
import GoPremium from "./Pages/Dashboard/GoPremium/index";
import MyCart from "./Lms/MyCart";
import ProfilePage from "./Lms/ProfilePage";
import BasicDetails from "./Lms/BasicDetails";
import AboutUs from "./Lms/AboutUs";
import DesignAndServices from "./Lms/DesignAndServices";
import ServicesComponent from "./Lms/ServicesComponent";
import DesignStylesComponent from "./Lms/DesignStylesComponent";
import ProjectComponent from "./Lms/ProjectComponent";
import PricesComponent from "./Lms/PricesComponent";
import AddNewProject from "./Lms/AddNewProject";
import PreferredLocation from "./Lms/PreferredLocation";
import OrderComplete from "./Lms/OrderComplete";
import ChooseProfession from "./Components/ChooseProfession";
import Plan from "./Lms/Plan";
import EmailVerification from "./Pages/Auth/emailVerification/EmailVeification";
import AboutIdesign from "./Lms/AboutIdesign";
import HowIdesignWorks from "./Lms/HowIdesignWorks";
import ResetPassword from "./Pages/Auth/ResetPassword/index";
import CongoWeb from "./Components/OnboardingWebScreens/CongoWeb";
import ChooseProfessionWeb from "./Components/newContractorOn/chooseProfessionWeb";
import ProfileBuildMob from "./Components/newContractorOn/profileBuildMob";
import FalseCeiling from "./Components/newContractorOn/falsecelling";
import Tiles from "./Components/newContractorOn/tiles";
import PremiumPlasticEmulsion from "./Components/newContractorOn/PremiumPlasticEmulsion";
import Wardrobes from "./Components/newContractorOn/wardrobes";
import Kitchen from "./Components/newContractorOn/kitchen";
import OemProfileBuild from "./Components/newOem/oemProfileBuild";
import OEMkitchen from "./Components/newOem/OEMkitchen";
import OEMaddWardrobePics from "./Components/newOem/oemUpload";
import OemSelectedImg from "./Components/newOem/oemSelectedImg";
import OemWadrobImgWeb from "./Components/newOem/oemWadrobImgWeb";
import Oemwardrobe from "./Components/newOem/oemWardrobe";
import OemAddPhotos from "./Components/newOem/oemAddPhotos";
import OemSavePhotos from "./Components/newOem/oemSavePhotos";
import OemAddKitchenPhotos from "./Components/newOem/oemaddkitechenphotos";
import OemSaveKitchenPhotos from "./Components/newOem/oemSaveKitchenPhotos";
import ViewYourReview from "./Lms/LmsWebComponents/ViewYourReviews";
import ProjectImages from "./Lms/LmsWebComponents/ProjectImages";
import EditDetails from "./Lms/LmsWebComponents/EditDetails";
import WebAddProjectPics from "./Lms/LmsWebComponents/WebAddProjectPics";
import UploadProjectimages from "./Lms/LmsWebComponents/UploadProjectImages";
import UploadedProjectSummaryWeb from "./Lms/LmsWebComponents/UploadedProjectSummaryWeb";
import MyReviewsComponent from "./Lms/MyReviewsComponent";
import SecureChat from "./Components/SecureChat/SecureChat/SecureChat";
import DashState from "./Components/SecureChat/picontext/DashBoard/dashState";
import LandingPage from "./3dComponents/LandingPage";
import TabButtons from "./3dComponents/TabButtons";
import TabButtons2 from "./3dComponents/TabButtons2";
import TabButtons3 from "./3dComponents/TabButtons3";
import MakeOne from "./3dComponents/MakeOne";
import { UploadPdf } from "./Lms/Actions/threedActions";
import Cart from "./3dComponents/Cart";
import CartPayHalf from "./3dComponents/CartPayHalf";
import Final3dPage from "./3dComponents/Final3dPage";
import UploadPdf2 from "./3dComponents/UploadPdf2";
import MyPlanCartWeb from "./Lms/LmsWebComponents/MyPlanCartWeb";
import MyPlanDetailsWeb from "./Lms/LmsWebComponents/MyPlanDetailsWeb";
// import VendorLanding from "./VendorComponents/VendorLanding";
// import VendorRateList from "./VendorComponents/VendorRateList";
import RefundsReturns from "./Pages/Refunds";
import VendorLanding from "./VendorComponentsMain/VendorLanding";
import VendorRateList from "./VendorComponentsMain/VendorRateList";
import VendorReqApproved from "./VendorComponentsMain/VendorReqApproved";
import VendorCongo from "./VendorComponentsMain/VendorCongo";
import VendorFinal from "./VendorComponentsMain/VendorFinal";
import EditRateList from "./VendorComponentsMain/EditRateList";
import DesignerVendorPage1 from "./VendorComponentsMain/DesignerVendorPage1";
import VendorCart from "./VendorComponentsMain/VendorCart";
import VendorCartMob from "./VendorComponentsMain/VendorCartMob";

// import RefundsReturns from "./Pages/Refunds";
import MeasurmentComponent from "./Lms/MeasurmentComponent";
import MeasurmentHomeType from "./Lms/MeasurmentHomeType";
import MyCartMeasurement from "./Lms/MyCartMeasurement";

import AnalyticsDashboard from "./Components/Dashboard/AnalyticsDashboard";

import MeasurmentFullHomeWeb from "./Lms/MeasurmentFullHomeWeb";
import ProjectDetailsWeb from "./Lms/ProjectDetailsWeb";
import OrderLanding from "./OrderHistory/OrderLanding";
import ThreeDHistory from "./OrderHistory/ThreeDHistory";
import MeasurementHistory from "./OrderHistory/MeasurementHistory";

// import NewProfilePage from "./Lms/LmsWebComponents/NewProfile/newprofile";
import ProjectAdd from "./Lms/LmsWebComponents/NewProfile/ProjectAdd";
import UploadImages from "./Lms/LmsWebComponents/NewProfile/UploadImages";
import ProjectSumm from "./Lms/LmsWebComponents/NewProfile/ProjectSumm";
import ImagesOfProject from "./Lms/LmsWebComponents/NewProfile/ImagesOfProject";
import EditProject from "./Lms/LmsWebComponents/NewProfile/EditProject";
import DeleteProject from "./Lms/LmsWebComponents/NewProfile/DeleteProjects";
import Contact from "./Pages/Contact";

import MeasurementFinal from "./Lms/MeasurementFinal";
import MeasurementRoomwiseAlt from "./Lms/MeasurementRoomwiseAlt";
import NewPlan from "./Lms/LmsWebComponents/NewProfile/NewPlan";
import Review from "./Lms/LmsWebComponents/NewProfile/Review";
import NewProfilePage from "./Lms/LmsWebComponents/NewProfile/NewProfilePage";
import ChangePassword from "./Lms/LmsWebComponents/NewProfile/ChangePassword";
import LeadManagement from "./Pages/LeadManagementPage/LeadManagement";
import BuyLeads from "./Pages/BuyLeads/BuyLeads";
import LandingPageMain from "./Lms/LmsWebComponents/SaasOnboarding/NewLandingPage/LandingPageMain";
import ProductMain from "./Lms/LmsWebComponents/SaasOnboarding/Products/ProductMain";
// import PricingPageMain2 from "./Lms/LmsWebComponents/SaasOnboarding/LandingPricing2/PricingPageMain2";
import SaasSignup from "./Lms/LmsWebComponents/SaasOnboarding/SaasSignup";
import SaasOtpVeri from "./Lms/LmsWebComponents/SaasOnboarding/SaasOtpVeri";
import SaasLogin from "./Lms/LmsWebComponents/SaasOnboarding/SaasLogin";
import PrincingNew from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/PrincingNew";
import PricingWebMob from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/PricingWebMob";
// import Checkoutt from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/Checkoutt.jsx";
import Paymentss from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/Paymentss";
// import PaymentsMob from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/onboardingMob/PaymentsMob";
import PersonalCombined from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/WebMobOnboard/PersonalCombined";
import CompanyCombined from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/WebMobOnboard/CompanyCombined";
import CompleteCombined from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/WebMobOnboard/CompleteCombined";
import ProfileCombined from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/WebMobOnboard/ProfileCombined";
import CreateCombined from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/WebMobOnboard/CreateCombined";
import FewCombined from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/WebMobOnboard/FewCombined";
import WelcomeCombined from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/WebMobOnboard/WelcomeCombined";
import AboutWebMob from "./Lms/LmsWebComponents/SaasOnboarding/AboutUs/AboutWebMob";
import DashBoardAnj from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/onBoarding/DashboardNew/DashBoardAnj";
import MainProfilePage from "./Lms/LmsWebComponents/SaasOnboarding/MyProfile/MainProfilePage";
import Architects from "./Lms/LmsWebComponents/SaasOnboarding/Products/Architects";
import Design from "./Lms/LmsWebComponents/SaasOnboarding/Products/Design";
import Contractor from "./Lms/LmsWebComponents/SaasOnboarding/Products/Contractor";
import Estate from "./Lms/LmsWebComponents/SaasOnboarding/Products/Estate";
import Retail from "./Lms/LmsWebComponents/SaasOnboarding/Products/Retail";
import MainComp from "./Lms/LmsWebComponents/SaasOnboarding/PricingPage/onBoarding/ClientDashboard/AllDashFiles/MainComp";
import ResetPass from "./Lms/LmsWebComponents/SaasOnboarding/ResetPass";
import Verification from "./Lms/LmsWebComponents/SaasOnboarding/Verification";
import Schedule from "./Components/Schedule/Schedule";
import ArchitectMob from "./Lms/LmsWebComponents/SaasOnboardingMobile/HeroImgPage.jsx/HeroImgPage/ArchitectMob";
import ConstructionMob from "./Lms/LmsWebComponents/SaasOnboardingMobile/HeroImgPage.jsx/HeroImgPage/ConstructionMob";
import DesignMob from "./Lms/LmsWebComponents/SaasOnboardingMobile/HeroImgPage.jsx/HeroImgPage/DesignMob";
import EstateMob from "./Lms/LmsWebComponents/SaasOnboardingMobile/HeroImgPage.jsx/HeroImgPage/EstateMob";
import RetailMob from "./Lms/LmsWebComponents/SaasOnboardingMobile/HeroImgPage.jsx/HeroImgPage/RetailMob";
import PricingMain from "./Lms/LmsWebComponents/SaasOnboarding/Pricing/PricingMain";
import { Toaster } from "react-hot-toast";
import MainPage from "./NewHome/MainPage";
import NewWhoWeServe from "./Lms/WhoWeServeNew/NewWhoWeServe";
import MainPageFeature from "./NewFeature/MainPage";
import MainPageAllFeatures from "./NewFeature/FeaturePages/MainPageAllFeatures";
import SingleDubaipage from "./Lms/LmsWebComponents/SaasOnboarding/Pricing/SingleDubaipage";
import Dubai from "./Lms/LmsWebComponents/SaasOnboarding/Pricing/Dubai";
import Australia from "./Lms/LmsWebComponents/SaasOnboarding/Pricing/Australia";

export default function App() {

  const navigateTo = useNavigate();

  localStorage.setItem("cameFrom", "b2b");
  const [data, setData] = useState({
    userData: null,
    typeOfService: null,
    designStyles: null,
    consultationCharges: null,
    designFeePerSqFt: null,
    perRoomDesignCharge: null,
    projects: null,
  });

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("token") === "null" ||
  //     (localStorage.getItem("token") === null &&
  //       window.location.pathname != "/reset-password" &&
  //       window.location.pathname != "/community/" &&
  //       window.location.pathname != "/designer-community" &&
  //       window.location.pathname != "/refunds" &&
  //       // window.location.pathname != "/emailverification" &&
  //       window.location.pathname != "/signup" &&
  //       window.location.pathname != "/login" &&
  //       window.location.pathname != "/otpverify" &&
  //       window.location.pathname != "/products" &&
  //       window.location.pathname != "/pricing")
  //   ) {
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     navigateTo("/");
  //   }
  // }, []);

  // console.log(data);
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      <Provider store={store}>
        <DashState>
          <OnboardingContext.Provider value={{ data, setData }}>
            <Routes>
              <Route
                exact
                path="/dashboard"
                // element={<AnalyticsDashboard />}
                element={<DashBoardAnj />}
              ></Route>
              <Route
                exact
                path="/clientDash"
                // element={<AnalyticsDashboard />}
                element={<MainComp />}
              ></Route>
              <Route exact path="/" element={<LandingPageMain />} />
              {/* {isDesktop ? ( */}
                <>
                  <Route exact path="/new/architect" element={<NewWhoWeServe />} />
                  <Route exact path="/new/design" element={<NewWhoWeServe />} />
                  <Route exact path="/new/contractor" element={<NewWhoWeServe />} />
                  <Route exact path="/new/estate" element={<NewWhoWeServe />} />
                  <Route exact path="/new/retail" element={<NewWhoWeServe />} />
                  <Route exact path="/architect" element={<Architects />} />
                  <Route exact path="/design" element={<Design />} />
                  <Route exact path="/contractor" element={<Contractor />} />
                  <Route exact path="/estate" element={<Estate />} />
                  <Route exact path="/retail" element={<Retail />} />
                </>
              {/* ) 
              : (
                <>
                  
                  <Route exact path="/architect" element={<ArchitectMob />} />
                  <Route exact path="/design" element={<DesignMob />} />
                  <Route exact path="/contractor" element={<ConstructionMob />} />
                  <Route exact path="/estate" element={<EstateMob />} />
                  <Route exact path="/retail" element={<RetailMob />} />
                </>
              )} */}

              {/* mobile route */}

              {/* <Route exact path="/" element={<Home />} /> */}
              <Route exact path="/profilebuild" element={<WelcomeCombined />} />
              <Route exact path="/addprojectpics" element={<AddProjectPics />}></Route>
              <Route path="/schedule" element={<Schedule />} />
              <Route exact path="/saveoredit" element={<SaveOrEditProject />}></Route>
              <Route exact path="/comp2" element={<Comp2 />} />
              <Route exact path="/designtype" element={<DesignStyles />} />
              <Route exact path="/consultation" element={<Consultation />}></Route>
              <Route exact path="/feepersquarefeet" element={<DesignFee />}></Route>
              <Route exact path="/perroomfee" element={<PerRoomCharge />}></Route>
              <Route exact path="/addproject" element={<AddProjects />}></Route>
              <Route exact path="/saveoreditproject" element={<SaveOrEditProject />}></Route>
              <Route exact path="/projectsummary" element={<ProjectSummary />}></Route>
              <Route exact path="/congratulations" element={<CongoWeb />}></Route>
              <Route exact path="/plans" element={<Plan />}></Route>
              {/* <Route exact path="/lead" element={<HomePage />}></Route> */}
              <Route exact path="/lead" element={<LeadManagement />}></Route>
              <Route exact path="/buy-leads" element={<BuyLeads />}></Route>
              {/* <Route exact path="/checkout/:id" element={<Checkout />}></Route> */}

              <Route exact path="/payment" element={<Paymentss />}></Route>
              {/* <Route
                exact
                path="/welcome"
                element={<WelcomeCombined />}
              ></Route> */}
              <Route exact path="/personal" element={<PersonalCombined />}></Route>
              <Route exact path="/company" element={<CompanyCombined />}></Route>
              <Route exact path="/complete" element={<CompleteCombined />}></Route>

              <Route exact path="/profile" element={<ProfileCombined />}></Route>
              <Route exact path="/create" element={<CreateCombined />}></Route>
              <Route exact path="/few" element={<FewCombined />}></Route>

              <Route exact path="/my-profile" element={<Profile />}></Route>
              <Route exact path="/terms" element={<Terms />}></Route>

              <Route exact path="/privacy-policy" element={<Policy />}></Route>

              <Route exact path="/products" element={<ProductMain />}></Route>
              <Route exact path="/pricing" element={<PricingWebMob />}></Route>
              <Route exact path="/auz" element={<Australia />}></Route>
              {/* <Route exact path="/pricing" element={<PricingMain />}></Route> */}
              {/*  dubai page route  */}

              {/* <Route exact path="/uae" element={<Dubai/>} /> */}
              <Route exact path="/uae" element={<SingleDubaipage/>} />

              <Route exact path="/signup" element={<SaasSignup />}></Route>
              <Route exact path="/login" element={<SaasLogin />}></Route>
              <Route exact path="/resetPassword" element={<ResetPass />}></Route>
              <Route exact path="/submit-otp" element={<Verification />}></Route>
              <Route exact path="/otpverify" element={<SaasOtpVeri />}></Route>
              <Route exact path="/go-premium" element={<GoPremium />}></Route>
              <Route exact path="/mycart" element={<MyCart />}></Route>
              <Route exact path="/mycart/:planId" element={<MyPlanCartWeb />}></Route>
              <Route exact path="/myprofilee" element={<NewProfilePage />}></Route>

              <Route exact path="/myprofile" element={<MainProfilePage />}></Route>
              <Route exact path="/my-plan-details" element={<MyPlanDetailsWeb />}></Route>
              <Route exact path="/basicdetails" element={<BasicDetails />}></Route>
              <Route exact path="/about" element={<AboutWebMob />}></Route>
              <Route exact path="/designandservices" element={<DesignAndServices />}></Route>
              <Route exact path="/editservices" element={<ServicesComponent />}></Route>
              <Route exact path="/editdesignstyles" element={<DesignStylesComponent />}></Route>
              <Route exact path="/projectpage" element={<ProjectComponent />}></Route>
              <Route exact path="/editprices" element={<PricesComponent />}></Route>
              <Route exact path="/addnewprojects" element={<AddNewProject />}></Route>
              <Route exact path="/preferredcities" element={<PreferredLocation />}></Route>
              <Route exact path="/order-placed" element={<OrderComplete />}></Route>
              <Route exact path="/emailverification" element={<EmailVerification />}></Route>
              {/* <Route
                exact
                path="/aboutidesign"
                element={<AboutIdesign />}
              ></Route> */}
              <Route exact path="/cookie-policy" element={<Terms />}></Route>
              <Route exact path="/contact" element={<Contact />}></Route>
              <Route exact path="/howidesignworks" element={<HowIdesignWorks />}></Route>
              <Route exact path="/reset-password" element={<ResetPassword />}></Route>
              <Route exact path="/chooseprofession" element={<ChooseProfessionWeb />}></Route>
              <Route exact path="/contractorprofilebuild" element={<ProfileBuildMob />}></Route>
              <Route exact path="/falseceiling" element={<FalseCeiling />}></Route>
              <Route exact path="/tiles" element={<Tiles />}></Route>
              <Route exact path="/premiumplasticemulsion" element={<PremiumPlasticEmulsion />}></Route>
              <Route exact path="/wardrobes" element={<Wardrobes />}></Route>
              <Route exact path="/kitchen" element={<Kitchen />}></Route>
              <Route exact path="/refunds" element={<RefundsReturns />}></Route>

              <Route exact path="/oemprofilebuild" element={<OemProfileBuild />}></Route>
              <Route exact path="/oemkitchen" element={<OEMkitchen />}></Route>
              <Route exact path="/oemwardrobe" element={<Oemwardrobe />}></Route>
              <Route exact path="/oemupload" element={<OEMaddWardrobePics />}></Route>

              <Route exact path="/oemsavewardrobeprojectpics" element={<OemSelectedImg />}></Route>
              <Route exact path="/oemwadrobimgweb" element={<OemWadrobImgWeb />}></Route>
              <Route exact path="/oemaddphotos" element={<OemAddPhotos />}></Route>
              <Route exact path="/oemsavephotos" element={<OemSavePhotos />}></Route>
              <Route exact path="/oemaddkitchenphotos" element={<OemAddKitchenPhotos />}></Route>
              <Route exact path="/oemsavekitchenphotos" element={<OemSaveKitchenPhotos />}></Route>
              {/* <Route exact path="/congrats" element={<Congratulations/>}></Route> */}
              {/* <Route exact path="/congratulations" element={<CongoWeb />}></Route> */}

              <Route exact path="/viewyourreview" element={<ViewYourReview />}></Route>
              <Route exact path="webaddprojectpics" element={<WebAddProjectPics />}></Route>
              <Route exact path="/projectpage/:id" element={<ProjectImages />}></Route>
              <Route exact path="/prjectpage/:id" element={<EditDetails />}></Route>
              <Route exact path="/uploadprojectimages" element={<UploadProjectimages />}></Route>
              <Route exact path="/uploadedprojectsummaryweb" element={<UploadedProjectSummaryWeb />}></Route>

              <Route exact path="/oemsavewardrobeprojectpics" element={<OemSelectedImg />}></Route>
              <Route exact path="/oemwadrobimgweb" element={<OemWadrobImgWeb />}></Route>
              <Route exact path="/oemaddphotos" element={<OemAddPhotos />}></Route>
              <Route exact path="/oemsavephotos" element={<OemSavePhotos />}></Route>
              <Route exact path="/oemaddkitchenphotos" element={<OemAddKitchenPhotos />}></Route>
              <Route exact path="/oemsavekitchenphotos" element={<OemSaveKitchenPhotos />}></Route>
              <Route exact path="/secure-chat" element={<SecureChat />}></Route>
              <Route exact path="/secure-chat/:chatId" element={<SecureChat />}></Route>
              {/* <Route exact path="/congrats" element={<Congratulations/>}></Route> */}
              {/* <Route exact path="/congratulations" element={<CongoWeb />}></Route> */}

              {/* <Route exact path="/viewyourreview" element={<ViewYourReview />}></Route>
              <Route exact path="/projectpage/:id" element={<ProjectImages />}></Route>
              <Route exact path="/prjectpage/:id" element={<EditDetails />}></Route> */}

              {/* 3d route */}
              <Route exact path="/3dLanding" element={<LandingPage />}></Route>
              <Route exact path="/3dUploadCad" element={<TabButtons />}></Route>
              <Route exact path="/3dSelectRoom" element={<TabButtons2 />}></Route>
              <Route exact path="/3dMakePPT" element={<TabButtons3 />}></Route>
              <Route exact path="/3dMakePPT/MakeOne" element={<MakeOne />}></Route>
              <Route exact path="/3dMakePPT/UploadPdf" element={<UploadPdf2 />}></Route>
              <Route exact path="/3dCart" element={<Cart />}></Route>
              <Route exact path="/3dCartHalf" element={<CartPayHalf />}></Route>
              <Route exact path="/3dFinal" element={<Final3dPage />}></Route>
              <Route exact path="/myreviewspage" element={<MyReviewsComponent />}></Route>
              <Route exact path="/measurementpage" element={<MeasurmentComponent />}></Route>
              <Route exact path="/measurementpage/hometype" element={<MeasurmentHomeType />}></Route>
              <Route exact path="/measurementpage/fullhome" element={<MeasurmentFullHomeWeb />}></Route>
              <Route exact path="/measurementpage/projectlocation" element={<ProjectDetailsWeb />}></Route>
              <Route exact path="/measurementpage/roomwsie" element={<MeasurementRoomwiseAlt />}></Route>
              <Route exact path="/measurementcart" element={<MyCartMeasurement />}></Route>
              {/* 3d route */}
              <Route exact path="/3dLanding" element={<LandingPage />}></Route>
              <Route exact path="/3dUploadCad" element={<TabButtons />}></Route>
              <Route exact path="/3dSelectRoom" element={<TabButtons2 />}></Route>
              <Route exact path="/3dMakePPT" element={<TabButtons3 />}></Route>
              <Route exact path="/3dMakePPT/MakeOne" element={<MakeOne />}></Route>

              <Route exact path="/3dMakePPT/UploadPdf" element={<UploadPdf2 />}></Route>

              <Route exact path="/3dCart" element={<Cart />}></Route>
              <Route exact path="/3dCartHalf" element={<CartPayHalf />}></Route>
              <Route exact path="/3dFinal" element={<Final3dPage />}></Route>
              <Route exact path="/myreviewspage" element={<MyReviewsComponent />}></Route>
              <Route exact path="/measurementpage" element={<MeasurmentComponent />}></Route>
              <Route exact path="/measurementpage/hometype" element={<MeasurmentHomeType />}></Route>
              <Route exact path="/measurementcart" element={<MyCartMeasurement />}></Route>

              {/* Vendor route */}
              {/* <Route exact path="/vendorPage" element={<VendorLanding />}></Route>
              <Route exact path="/vendorRateList" element={<VendorRateList />}></Route> */}
              <Route exact path="/measurementfinal" element={<MeasurementFinal />}></Route>

              {/* Vendor route */}
              <Route exact path="/vendor-page" element={<VendorLanding />}></Route>
              <Route exact path="/vendor-ratelist" element={<VendorRateList />}></Route>
              <Route exact path="/vendor-request-approved" element={<VendorReqApproved />}></Route>
              {/* <Route exact path="/vendor-congratulations" element={<VendorCongo />}></Route> */}
              {/* <Route exact path="/vendor-final" element={<VendorFinal />}></Route> */}
              <Route exact path="/vendor-edit-rate-list" element={<EditRateList />}></Route>
              <Route exact path="/vendor-list-designer" element={<DesignerVendorPage1 />}></Route>
              <Route exact path="/vendorcart" element={<VendorCartMob />}></Route>

              {/* Order History route*/}
              <Route exact path="/orderpage" element={<OrderLanding />}></Route>
              <Route exact path="/threedhistory" element={<ThreeDHistory />}></Route>
              <Route exact path="/measurementhistory" element={<MeasurementHistory />}></Route>

              {/*New profile page routes route*/}
              <Route exact path="/changepass" element={<ChangePassword />}></Route>
              {/* <Route exact path="/myprofile" element={<NewProfilePage/>}></Route> */}
              <Route exact path="/newplan" element={<NewPlan />}></Route>
              <Route exact path="/myreview" element={<Review />}></Route>
              <Route exact path="/uploadImages" element={<UploadImages />}></Route>
              <Route exact path="/projectadd" element={<ProjectAdd />}></Route>
              <Route exact path="/projectsumm" element={<ProjectSumm />}></Route>
              <Route exact path="/summaryimages" element={<ImagesOfProject />}></Route>
              <Route exact path="/summaryimages/:id" element={<EditProject />}></Route>
              <Route exact path="/sumaryimages/:id" element={<DeleteProject />}></Route>
              
              <Route exact path="/home" element={<MainPage />}></Route>
              <Route exact path="/features" element={<MainPageFeature />}></Route>

              <Route exact path="/fileManager" element={<MainPageAllFeatures />} />
              <Route exact path="/taskManager" element={<MainPageAllFeatures />} />
              <Route exact path="/mom" element={<MainPageAllFeatures />} />
              <Route exact path="/rfi" element={<MainPageAllFeatures />} />
              <Route exact path="/materialRequest" element={<MainPageAllFeatures />} />
              <Route exact path="/timelineTimesheet" element={<MainPageAllFeatures />} />
              <Route exact path="/quotation" element={<MainPageAllFeatures />} />
              <Route exact path="/account" element={<MainPageAllFeatures />} />
              <Route exact path="/vendorPO" element={<MainPageAllFeatures />} />
              <Route exact path="/newLead" element={<MainPageAllFeatures />} />
              <Route exact path="/chat" element={<MainPageAllFeatures />} />
              <Route exact path="/libraryTemplates" element={<MainPageAllFeatures />} />
              <Route exact path="/itemLibraryInventory" element={<MainPageAllFeatures />} />
              <Route exact path="/attendance" element={<MainPageAllFeatures />} />
              <Route exact path="/companyManagement" element={<MainPageAllFeatures />} />
              <Route exact path="/teamManagement" element={<MainPageAllFeatures />} />
              <Route exact path="/mobile" element={<MainPageAllFeatures />} />
              <Route exact path="/extras" element={<MainPageAllFeatures />} />
            </Routes>
          </OnboardingContext.Provider>
        </DashState>
      </Provider>
      <Toaster />
    </>
  );
}
