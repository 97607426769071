import React from 'react';
import styles from "./landingPageMobCss/section3.module.css";
import img1 from "../imagesMobImg/section3/mob2.png";
import quo from "../imagesMobImg/section3/quo.png";
import po from "../imagesMobImg/section3/po.png";
import acc from "../imagesMobImg/section3/accounts.png";
import vendor from "../imagesMobImg/section3/vendor.png";

const Section3Mob = () => {
  return (
    <>
    <div className={styles.container} style={{paddingLeft:"1.5rem",paddingRight:"1.5rem"}}>
      
        <div className={styles.content_div}>
         <div ><button className={styles.btn}>Cost Management</button></div> 
          <div className={styles.heading}>
          Efficiently manage your finances, unleash your creativity
          </div>
          <div className={styles.img_div}>
           <img src={img1} alt="" className={styles.mainImg}/>
             
        </div>
          <div className={styles.projects_div}>
            <div className={styles.file_timeline}>
              <div style={{textAlign:"center",width:"50%"}}>
                <img src={quo} alt="" className={styles.icon}/>
                <div className={styles.icon_name}>QUOTATION</div>
                <p className={styles.para}>Improve accuracy, <br /> streamline your workflow,</p>
              </div>
              <div style={{textAlign:"center",width:'50%'}}>
                <img src={po} alt="" className={styles.icon}/>
               <div className={styles.icon_name}>PURCHASE ORDER</div> 
               <p className={styles.para}>Create and Issue <br /> purchase order to the vendors.</p>
              </div>
          
            </div>
            <div className={styles.task_daily}>
            <div style={{textAlign:"center",width:'50%'}}>
                <img src={acc} alt="" className={styles.icon}/>
                <div className={styles.icon_name}>ACCOUNTS</div>
                <p className={styles.para}>Simplifies project <br /> finances, saves time,<br /> improves accuracy,</p>
              </div>
              <div style={{textAlign:"center",width:'50%'}}>
                <img src={vendor} alt="" className={styles.icon}/>
               <div className={styles.icon_name}>VENDORS</div> 
               <p className={styles.para}>Manage different <br /> vendors with unified <br /> dashboard.</p>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      </>
  )
}

export default Section3Mob;