import React from "react";
import styles from "../../onBoardingCss/DashboardCssNew/dashboardTop.module.css";
import icon1 from "../dashImgs/account_circle.svg";
import icon2 from "../dashImgs/attach_money.svg";
import icon3 from "../dashImgs/overview_key.svg";
import icon4 from "../dashImgs/shopping_cart.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";


const DashBoardTop = () => {
  const [getData, setgetData] = useState([]);

  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  const GetName = async () => {
    await axios
      .get("https://pro-api.idesign.market/user/profile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (response) {
        // console.log(response?.data?.data);
        setgetData(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetName();
  }, []);

  // get task data
  const [task, settask] = useState([]);

  const [overalldata, setOveralldata] = useState({
    activeTasks: 0,
    delayTasks: 0,
    pendencies: 0,
  });
  const GetTask = async () => {
    await axios
      .get(
        `https://pmt.idesign.market/api/projects/get-project-summary?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then(function (response){
        console.log(response?.data);
        settask(response?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (task?.length > 0) {
      let val1 = 0,
        val2 = 0,
        val3 = 0;

      task?.map((items) => {
        val1 += items?.delayTasksCount;
        val2 += items?.pendenciesCount;
        val3 += items?.myTasksCount;
      });

      setOveralldata((prev) => {
        return {
          ...prev,
          activeTasks: val1,
          delayTasks: val3,
          pendencies: val2,
        };
      });
    }
  }, [task]);

  // console.log(overalldata);

  // get accounts summary
  const [acc, setacc] = useState([]);
  const GetAccounts = async () => {
    await axios
      .get(
        `https://pmt.idesign.market/api/accounts/get-accounts-summary?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then(function (response) {
        console.log(response?.data);
        setacc(response?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // get leads summary
  const [leads, setleads] = useState([]);
  const getLeads = async () => {
    await axios
      .get(`https://pro-api.idesign.market/user/newleads?pageNo=0`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (response) {
        // console.log(response?.data?.data);
        setleads(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetTask();
    GetAccounts();
    getLeads();
  }, []);

  // console.log(leads);

  const calculateQuotation = (number) => {
    if (number >= 10000000) {
      return (number / 10000000).toFixed(2) + " Cr";
    } else if (number >= 100000) {
      return (number / 100000).toFixed(2) + " L";
    }
    // return number.toLocaleString();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>{getData?.companyName} Dashboard</div>
        <div className={styles.threeBoxDiv}>
          {/* task box */}
          {task?.length > 0 ? (
            <div className={styles.firstData_box}>
              <div className={styles.box_top}>
                <div className={styles.task}>
                  <img src={icon3} alt="" />
                  Task
                </div>
                <div className={styles.totalTask}>
                  Total Task :{task?.length}
                </div>
              </div>
              <div className={styles.box_last}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.leftText}>In-Progress</div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // border: "2px solid red",
                    }}
                  >
                    <div className="progress_bar_1">
                      <ProgressBar
                        striped
                        now={overalldata?.activeTasks}
                        style={{ height: "0.5rem" }}
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>
                      {overalldata?.activeTasks}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.leftText}>Delayed</div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="progress_bar_2">
                      <ProgressBar
                        striped
                        now={overalldata?.delayTasks}
                        style={{ height: "0.5rem" }}
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>
                      {overalldata?.delayTasks}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                    // width:"99%",
                  }}
                >
                  <div className={styles.leftText}>Not Started</div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="progress_bar_3">
                      <ProgressBar
                        striped
                        now={20}
                        style={{ height: "0.5rem" }}
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>20</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.leftText}>Completed</div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="progress_bar_4">
                      <ProgressBar
                        striped
                        now={40}
                        style={{ height: "0.5rem" }}
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>40</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.first_box}>
              <div className={styles.task}>
                <img src={icon3} alt="" />
                <span>Tasks</span>
              </div>
              <div className={styles.noTask}>No tasks to show</div>
              <a
                href="https://www.idesign.market/timeline-beta/task-manager-dashboard"
                target="_blank"
              >
                <button className={styles.btn}>Create Tasks</button>
              </a>
            </div>
          )}

          {/* accounts box */}

          {acc?.boqValue != 0 ? (
            <div className={styles.accountsData_box}>
              <div className={styles.box_top}>
                <div className={styles.task}>
                  <img src={icon2} alt="" /> Accounts
                </div>
                <div className={styles.totalTask}>
                  Total Quotation: {calculateQuotation(acc?.boqValue)}
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <div
                    className={styles.leftTextt}
                    style={{ marginTop: "0.7rem" }}
                  >
                    <img
                      src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207496.svg"
                      alt=""
                    />
                    <span style={{ marginLeft: "0.5rem" }}>
                      Received: ₹ {acc?.paymentReceived}
                    </span>
                  </div>
                  <div className={styles.leftTextt}>
                    <img
                      src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207496.svg"
                      alt=""
                    />
                    <span style={{ marginLeft: "0.7rem" }}>
                      Expense: ₹ {acc?.expencesMade}
                    </span>
                  </div>
                  <div className={styles.leftTextt}>
                    <img
                      src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207496.svg"
                      alt=""
                    />
                    <span style={{ marginLeft: "0.8rem" }}>
                      Balance: ₹ {acc?.balance}
                    </span>
                  </div>
                </div>
                <div>
                  <img
                    src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202610182.svg"
                    alt=""
                    style={{ width: "100%", marginRight: "1rem" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.first_box}>
              <div className={styles.task}>
                <img src={icon2} alt="" />
                <span>Accounts</span>
              </div>
              <div className={styles.noTask}>No Financials to show</div>
              <a
                href="https://www.idesign.market/accounts-beta/account/journal"
                target="_blank"
              >
                <button className={styles.btn}>Create a Journal</button>
              </a>
            </div>
          )}

          {/* leads */}
          {leads?.data?.length > 0 ? (
            <div className={styles.firstData_box}>
              <div className={styles.box_top}>
                <div className={styles.task}>
                  <img src={icon1} alt="" />
                  Leads
                </div>
                <div className={styles.totalTask}>
                  Total Leads :
                  {leads?.interested +
                    leads?.not_interested +
                    leads?.no_response +
                    leads?.followUp}
                </div>
              </div>
              <div className={styles.box_last}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.leftText}>Interested</div>

                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="progress_bar_5">
                      <ProgressBar
                        striped
                        now={leads?.interested}
                        style={{ height: "0.5rem" }}
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>{leads?.interested}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.leftText}>Follow up</div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="progress_bar_6">
                      <ProgressBar
                        striped
                        now={leads?.followUp}
                        style={{ height: "0.5rem" }}
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>{leads?.followUp}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.leftText}>Not Interested</div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="progress_bar_7">
                      <ProgressBar
                        striped
                        now={leads?.not_interested}
                        style={{ height: "0.5rem" }}
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>
                      {leads?.not_interested}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.leftText}>Not Response</div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="progress_bar_8">
                      <ProgressBar
                        striped
                        now={leads?.no_response}
                        style={{ height: "0.5rem" }}
                      />
                    </div>
                    <div style={{ fontSize: "15px" }}>{leads?.no_response}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.first_box}>
              <div className={styles.task}>
                <img src={icon1} alt="" />
                <span>Leads</span>
              </div>
              <div className={styles.noTask}>No Leads to show</div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2rem",
                  gap: "1rem",
                }}
              >
                <a href="https://www.idesign.market/buy-leads" target="_blank">
                  <button className={styles.btnn}>
                    <img src={icon4} alt="" /> Buy Leads
                  </button>
                </a>

                <a
                  href="https://www.idesign.market/leads/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className={styles.addLeads}>Add Leads</div>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashBoardTop;
