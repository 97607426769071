import React from "react";
import styles from "./landingPageMobCss/section4mob.module.css";
import buy from "../imagesMobImg/section4/buy.png";
import profile from "../imagesMobImg/section4/profile.png";
import manage from "../imagesMobImg/section4/profilered.png";
import img1 from "../imagesMobImg/section4/mob3.png";

const Section4Mob = () => {
  return (
    <>
      <div className={styles.container} style={{paddingLeft:"1.5rem",paddingRight:"1.5rem"}}>
        <div className={styles.content_div}>
          <div>
            <button className={styles.btn}>Lead Management</button>
          </div>
          <div className={styles.heading}>
            Managing your Interior Project was Never been this easy
          </div>
          <div className={styles.img_div}>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Frame%202611060.svg"
              alt=""
              className={styles.mainImg}
            />
          </div>
          <div className={styles.projects_div}>
            <div className={styles.file_timeline}>
              <div style={{ textAlign: "center", width: "50%" }}>
                <img src={manage} alt="" className={styles.icon} />
                <div className={styles.icon_name}>MANAGE LEADS</div>
                <p className={styles.para}>
                  Effortlessly manage <br /> your interior design projects
                </p>
              </div>
              <div
                style={{
                  textAlign: "center",
                  width: "50%",
                  paddingLeft: "0.8rem",
                }}
              >
                <img src={profile} alt="" className={styles.icon} />
                <div className={styles.icon_name}>PROFILE LISTING</div>
                <p className={styles.para}>
                  Stay on track with <br /> your projects from <br /> start to
                  finish
                </p>
              </div>
            </div>
            <div className={styles.task_daily}>
              <div style={{ textAlign: "center", width: "50%" }}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/password%20%281%29.svg" alt="" className={styles.icon} />
                <div className={styles.icon_name}>CLIENT LOGIN</div>
                <p className={styles.para}>
                  Better project updates with login for your clients.
                </p>
              </div>
              <div
                style={{
                  textAlign: "center",
                  width: "50%",
                  paddingLeft: "0.8rem",
                }}
              >
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/priority%20%281%29.svg" alt="" className={styles.icon} />
                <div className={styles.icon_name}>CLIENT SIGN OFF</div>
                <p className={styles.para}>
                  Get approvals from your clients in minutes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section4Mob;
