export const constants = {
  B2B_BASE: "https://pro-api.idesign.market",
  COMMUNITY_BASE: "https://community-api.idesign.market",
  PO_BASE: "https://po-api.idesign.market",
  PMT_BASE: "https://pmt.idesign.market",
  QUOT_BASE: "https://quotation-api.idesign.market",
  BASE_URL: "/po-beta",
  emailVerifyURL:"https://email-api.idesign.market/api/verify",
  PRO_URL:"https://pro.idesign.market/",
  notificationAPI:"https://notify-api.essentiaenvironments.com",
  threeDApi:"https://3d-api.idesign.market",
};

export const BASE_URL_ESS = "https://www.idesign.market/";
export function getToken() {
    return `Bearer ${localStorage.getItem("token")}`;
}

export const otherPagesURL={
  facebook:"https://www.facebook.com/idesign.market/",
  instagram:"https://www.instagram.com/iDesign.market/",
  whatsapp:"https://api.whatsapp.com/send/?phone=%2B919289347893&text=Hi%2C+pls+resolve+my+below+query&app_absent=0",
  youtube:"https://www.youtube.com/channel/UCZYsSoot4r9eZSPJk6F7-xw",
  careers:"https://angel.co/company/idesign-market-1",
  magazine:"https://magazine.idesign.market/",
  design_professionals:"https://www.idesign.market/find-professionals",
}